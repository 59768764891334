.mask_open {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(53,53,53,.8);
  //animation: maskShow @animationDelay;
  overflow: auto;
}

.mask_close {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(53,53,53,.8);
  animation: maskClose @animationDelay;
  overflow: auto;
}


.DialogContainer_open {
  background: transparent;
  width: calc(100vw - 12px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show @animationDelay;
  color: #1f1f1f;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
}

.DialogContainer_close {
  background: transparent;
  width: calc(100vw - 12px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #1f1f1f;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
  animation: close @animationDelay;
}

@keyframes maskShow {
  from {
    background: rgba(53,53,53,0);
  }
  to {
    background: rgba(53,53,53,0.5);
  }
}

@keyframes maskClose {
  from {
    background: rgba(53,53,53,0.5);
  }
  to {
    background: rgba(53,53,53,0);
  }
}

@keyframes show {
  from {
    top: 48%;
    left: 50%;
    transform: translate(-50%, -48%);
    opacity: 0;
  }
  to {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes close {
  from {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  to {
    top: 48%;
    left: 50%;
    transform: translate(-50%, -48%);
    opacity: 0;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;