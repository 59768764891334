.Dialog_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 800;

  .Dialog_header_close {
    position: absolute;
    right: -44px;
    cursor: pointer;
    top: 0;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;