.mj_header_container {
  position: relative;
  height: 106px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    content: '';
    width: 100%;
    height: 106px;
    background-color: rgba(0, 0, 0, .65);
    backdrop-filter: blur(8px);
  }

  .mj_header_logo {
    position: relative;
    margin: 17px 0 0 44px;
    width: auto;
    height: 72px;
    z-index: 2;
  }

  .mj_header_menuContainer {
    opacity: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    transition: visibility @animationDelay;
    visibility: hidden;
  }
  .mj_header_menuContainer_show {
    visibility: visible;
    display: block;
    animation: @animationDelay menuShow;
    opacity: 1;
  }
  .mj_header_menuContainer_hide {
    animation: @animationDelay menuHide;
    pointer-events: none;
    opacity: 0;
  }

  @keyframes menuShow {
    from {
      top: 40px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @keyframes menuHide {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: -40px;
      opacity: 0;
    }
  }
  .mj_header_nav_closeBtn {
    position: absolute;
    right: 44px;
    top: 36px;
  }
  .mj_header_nav_container {
    width: 419px;
    position: absolute;
    top: 111px;
    right: 6px;
    overflow: hidden;
    transition: 0.6s;
  }
  .mj_header_nav_list {
    overflow: hidden;
    transition: @animationDelay;
  }
  .mj_header_nav_item {
    display: block;
    text-align: center;
    font-size: 26px;

    font-weight: 400;
    color: #B7B3B3;
    padding: 0 30px;
    background: rgba(255,255,255);
    &:last-child {
      border-bottom: 0;
      margin-bottom: 6px;
    }
  }
  .mj_header_nav_item_active {
    background: @primary-color;
    font-size: 26px;

    font-weight: 500;
    color: #615B41;
  }
  .mj_header_nav_item_text {
    width: 100%;
    height: 94px;
    line-height: 94px;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgba(190, 190, 190, .3);
    align-items: center;
    svg {
      font-size: 40px;
    }
  }
  .mj_header_nav_selectLang_container {
    transition: @animationDelay;
  }
  .mj_openMenu_icon_body {
    position: absolute;
    width: 44px;
    height: 30px;
    right: 44px;
    top: 36px;
  }
  .mj_openMenu_icon {
    display: block;
    background: #fff;
    position: absolute;
    cursor: pointer;
    height: 4px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    border-radius: 4px;
  }
  .mj_openMenu_icon:before {
    content: "";
    display: inline-block;
    height: 4px;
    left: 0;
    top: -14px;
    background: #fff;
    position: absolute;
    transform-origin: 50% 50%;
    width: 100%;
    border-radius: 4px;
  }
  .mj_openMenu_icon:after {
    content: "";
    display: inline-block;
    height: 4px;
    left: 0;
    top: 14px;
    background: #fff;
    position: absolute;
    transform-origin: 50% 50%;
    width: 100%;
    border-radius: 4px;
  }
}

.mj_header_dark_mask {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: @animationDelay;
  background: rgba(53, 53, 53, 0);
  overflow: auto;
  z-index: 99;
  pointer-events: none;
}

.mj_header_dark_mask_show {
  pointer-events: auto;
  background: rgba(53, 53, 53, .8);
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;