@import "initial.less";
@import "iconfont.less";

.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

@font-face {
  font-family: 'msmincho';
  src: url('../public/font/msmincho.ttf') format("opentype");
};

@font-face {
  font-family: 'Alfphabet';
  src: url('../public/font/Alfphabet-IV.ttf') format("opentype");
};

@font-face {
  font-family: 'Fontspring';
  src: url('../public/font/FontFont_FF.Mark.Pro.Heavy.otf') format("opentype");
};


@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;