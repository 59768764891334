.mj_404_container {
  height: 100vh;

  .mj_404_bgc {
    width: 100%;
    position: fixed;
    object-fit: cover;
  }

  .mj_404_pic {
    width: 100%;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;