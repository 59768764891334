.mj_news_container {
  overflow: hidden;
  background: #22211E;
  .swiper_container{
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .mj_joinUs_international_title {
    height: 277px;
    padding-left: 67px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mj_joinUs_international_title_text {
      font-size: 78px;
      height: 78px;
      line-height: 78px;

      font-weight: 800;
      color: #FFFFFF;
      margin-bottom: 14px;
    }
    .mj_joinUs_international_title_subTitle {
      font-size: 31px;
      height: 31px;
      line-height: 31px;
      font-family: Fontspring;
      font-weight: 400;
      color: #FFE66F;
    }
  }
  .mj_news_item {
    background: #2E2C29;
    width: 100%;
    height: 263px;
    padding: 6px;
    margin-bottom: 6px;
    display: flex;
    .mj_news_pic {
      position: relative;
      background: #ECECEC;
      width: 250px;
      height: 250px;
      overflow: hidden;
    }
    .mj_news_detail_container {
      position: relative;
      flex: 1;
      .mj_news_detail{
        position: relative;
        .mj_news_title {
          padding: 16px 0 20px 31px;
          line-height: 38px;
          font-size: 31px;

          font-weight: 400;
          color: #FFFFFF;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 这里是超出几行省略 */
          overflow: hidden;
        }
        .mj_news_desc {
          overflow: hidden;
          font-size: 20px;
          padding-top: 20px;

          font-weight: 300;
          height: 75px;
          color: #9D9D9D;
          line-height: 28px;
          border-top: 2px solid #45413A;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0 25px;
        }
      }
      .mj_news_linkBtn {
        position: absolute;
        bottom: 0;
        width: calc(100% - 6px);
        height: 50px;
        display: flex;
        background: #22211E;
        left: 6px;
        padding: 0 6px 0 27px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: #9D9D9D;
        div:first-child {
          font-size: 19px;
        }
        div:nth-child(2) {
          font-size: 22px;
          float: right;
          position: absolute;
          right: 0;
          margin-right: 55px;
        }
      }
      .mj_news_linkBtn_rightBtn {
        position: absolute;
        right: 6px;
        width: 38px;
        height: 38px;
        color: @primary-color;
        background: #2D2B27;
        .mj_news_linkBtn_rightBtn_icon {
          font-size: 38px;
        }
      }
    }
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;