.mj_JoinUs_container {
  overflow: hidden;
  background: #22211E;
  transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
  opacity: 1;
  position: relative;
  margin-top: 113px;
  padding: 6px;
  .mj_joinUs_position_body {
    margin-bottom: 4px;
    .mj_joinUs_position_item {
      height: 125px;
      background: #2D2B27;
      margin-top: 6px;
      padding: 0 46px 0 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.25s;
      &:hover {
        background: #3B3934;
      }
      &:last-child {
        margin-right: 0;
      }
      .mj_joinUs_position_name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .mj_joinUs_position_name_dot {
        width: 6px;
        height: 6px;
        background: #FFFFFF;
        margin-right: 8px;
      }
      .mj_joinUs_position_name_text {
        font-size: 31px;

        font-weight: 300;
        color: #FEFEFE;
      }
      .mj_joinUs_position_subName {
        font-size: 27px;
        font-family: Visby CF, sans-serif;
        font-weight: 500;
        color: #45423D;
        display: flex;
        justify-content: center;
        div:first-child {
          margin-right: 20px;
        }
        .mj_joinUs_position_right_arrow {
          font-size: 40px;
          color: @primary-color;
          transition: 0.25s linear;
        }
        .mj_joinUs_position_right_arrow_active {
          transform: rotateZ(90deg);
        }
      }
    }
    .mj_joinUs_position_item_active {
      background: #3B3934;
      .mj_joinUs_position_name_dot {
        background: @primary-color;
      }
      .mj_joinUs_position_name_text {
        color: @primary-color;
      }
    }
    .mj_joinUs_global_position_title {
      display: flex;
      height: auto;
      justify-content: space-between;
      margin: 6px 0;
      .mj_joinUs_global_position_title_item {
        text-align: center;
        height: 62px;
        line-height: 62px;
        background: #3B3934;
        flex: 1;
        font-size: 25px;

        font-weight: 300;
        color: #FEFEFE;
        margin-right: 4px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .mj_joinUs_global_position_body {
    width: 100%;
    opacity: 0;
  }
  .mj_joinUs_global_position_list {
    height: 0;
    overflow: hidden;
    transition: 0.4s linear;
    .mj_joinUs_global_position_list_item {
      width: 100%;
      display: flex;
      text-decoration: none;
      justify-content: space-between;
      align-self: center;
      font-size: 23px;
      min-height: 91px;

      font-weight: 300;
      color: #FEFEFE;
      cursor: pointer;
      &:last-child {
        border-bottom: 1px solid #3B3934;
      }
      div {
        border-top: 1px solid #3B3934;
        flex: 1;
        word-break: break-word;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
  .mj_joinUs_moka {
    height: 125px;
    line-height: 125px;
    width: 100%;
    background: #2D2B27;
    text-align: center;
    font-size: 22px;

    font-weight: 300;
    color: #FEFEFE;
  }
  .mj_joinUs_bottom_text {
    text-align: center;
    width: 100%;
    font-size: 16px;
    height: 61px;
    line-height: 61px;
    font-family: Mark Pro, sans-serif;
    font-weight: 200;
    color: #483D3C;
  }
  .mj_joinUs_international_title {
    height: 277px;
    padding-left: 67px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mj_joinUs_international_title_text {
      font-size: 78px;
      height: 78px;
      line-height: 78px;

      font-weight: 800;
      color: #FFFFFF;
      margin-bottom: 14px;
    }
    .mj_joinUs_international_title_subTitle {
      font-size: 31px;
      height: 31px;
      line-height: 31px;
      font-family: Fontspring;
      font-weight: 400;
      color: #FFE66F;
    }
  }
  .mj_joinUs_international_dot {
    width: 4px;
    height: 4px;
    background: #858585;
    margin: 0 15px;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;