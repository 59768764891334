@font-face {
  font-family: "iconfont"; /* Project id 3959928 */
  src: url('/public/iconfont/iconfont.woff2?t=1680073948484') format('woff2'),
  url('/public/iconfont/iconfont.woff?t=1680073948484') format('woff'),
  url('/public/iconfont/iconfont.ttf?t=1680073948484') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiantou-41:before {
  content: "\e620";
}

.icon-cafe-40:before {
  content: "\e61f";
}

.icon-ICON-39:before {
  content: "\e61e";
}

.icon-bilibili:before {
  content: "\e61d";
}

.icon-ICON-30:before {
  content: "\e61c";
}

.icon-ICON-25:before {
  content: "\e60d";
}

.icon-ICON-28:before {
  content: "\e60e";
}

.icon-ICON-23:before {
  content: "\e60f";
}

.icon-ICON-27:before {
  content: "\e610";
}

.icon-ICON-22:before {
  content: "\e611";
}

.icon-ICON-24:before {
  content: "\e612";
}

.icon-ICON-36:before {
  content: "\e613";
}

.icon-ICON-29:before {
  content: "\e614";
}

.icon-ICON-31:before {
  content: "\e615";
}

.icon-ICON-37:before {
  content: "\e616";
}

.icon-ICON-35:before {
  content: "\e617";
}

.icon-ICON-33:before {
  content: "\e618";
}

.icon-ICON-34:before {
  content: "\e619";
}

.icon-ICON-32:before {
  content: "\e61a";
}

.icon-ICON-38:before {
  content: "\e61b";
}

.icon-ICON-37-copy:before {
  content: "\e621";
}

