.mj_aboutUs_container {
  position: relative;
  overflow: hidden;
  height: var(--vh);
  .mj_aboutUs_content{
    position: absolute;
    left: 83px;
    top: calc(50% + 97px);
    transform: translateY(calc(-50% - 194px));
    opacity: 1;
    transition: @animationDelay;
    .mj_aboutUs_content_title {
      margin-bottom: 83px;
      .mj_aboutUs_content_mainTitle {

        font-weight: 800;
        color: #FFFFFF;
        height: 94px;
        line-height: 94px;
        margin-bottom: 6px;
        font-size: 58px;
      }
      .mj_aboutUs_content_subTitle {
        font-size: 42px;
        font-family: Fontspring, sans-serif;
        color: #FFE66F;
        font-weight: 400;
        height: 42px;
        line-height: 42px;
      }
    }
    .mj_aboutUs_content_detail {
      white-space: pre-line;
      width: 465px;
      word-break: break-word;
      font-size: 22px;

      font-weight: 400;
      color: #FFF;
      line-height: 39px;
    }
  }
  .mj_aboutUs_content_hide {
    left: 0;
    opacity: 0;
  }
  .aboutUs_bottom_body {
    transition: @animationDelay;
    transform: translateY(0);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .aboutUs_link_body {
      width: 100%;
      display: grid;
      padding: 0 6px;
      transform: translateY(-69px);
      grid-template-columns: 1fr 52px;
      grid-gap: 6px;
      .aboutUs_link {
        height: 125px;
        background: @primary-color;
        font-size: 26px;

        font-weight: bold;
        color: #22211E;
        text-align: center;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 54px;
        transition: @animationDelay;
        cursor: pointer;
        opacity: 1;
        overflow: hidden;
        position: relative;
        .aboutUs_link_dot {
          width: 6px;
          height: 6px;
          background: #22211E;
        }
        .aboutUs_link_dot_left {
          margin-right: 12px;
        }
        .aboutUs_link_dot_right {
          margin-left: 12px;
          margin-right: 52px;
        }
        .aboutUs_link_subtitle {
          font-size: 18px;
          font-family: Visby CF, sans-serif;
          font-weight: 300;
          color: #DFC651;
          position: absolute;
          right: 56px;
        }

      }
      .aboutUs_link_icon {
        width: 52px;
        height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        background: #22211E;
        overflow: hidden;
        .aboutUs_link_right_arrow {
          color: @primary-color;
          transform: rotateY(180deg) scale(1.1, 2.6);
        }
      }

    }
    .aboutUs_scrollTips {
      width: calc(100% - 12px);
      height: 63px;
      background: #22211E;
      position: absolute;
      bottom: 0;
      left: 6px;
      font-size: 18px;
      font-family: Visby CF, sans-serif;
      font-weight: normal;
      color: #483D3C;
      line-height: 63px;
      text-align: center;
    }
    .aboutUs_zh_twFooter {
      position: absolute;
      width: calc(100% - 12px);
      left: 6px;
      bottom: 0;
      background: #22211E;
      height: 93px;
      padding-top: 8px;
      overflow: hidden;
    }
  }

  .aboutUs_bottom_body_hide {
    transition: @animationDelay;
    transform: translateY(240px);
  }

  .aboutUs_link_body_zh_tw{
    transform: translateY(-99px) !important;
  }

  .mj_joinUs_container {
    height: 208px;
    overflow: hidden;
    background: #22211E;
    margin-top: 4px;
    padding: 4px;
    transition: @animationDelay;
    opacity: 1;
    position: relative;
  }
  .mj_joinUs_container_show{
    height: calc(100vh - 160px);
  }
  .mj_joinUs_container_hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .mj_joinUs_position_body {
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 4px;
    .mj_joinUs_position_item {
      height: 80px;
      width: 100%;
      background: #2D2B27;
      margin-right: 4px;
      cursor: pointer;
      padding: 0 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.25s;
      &:hover {
        background: #3B3934;
      }
      &:last-child {
        margin-right: 0;
      }
      .mj_joinUs_position_name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .mj_joinUs_position_name_dot {
        width: 4px;
        height: 4px;
        background: #FFFFFF;
        margin-right: 8px;
      }
      .mj_joinUs_position_name_text {
        font-size: 20px;

        font-weight: 300;
        color: #FEFEFE;
      }
      .mj_joinUs_position_subName {
        font-size: 17px;
        font-family: Visby CF, sans-serif;
        font-weight: 500;
        color: #45423D;
      }
    }
    .mj_joinUs_position_item_active {
      background: #3B3934;
      .mj_joinUs_position_name_dot {
        background: @primary-color;
      }
      .mj_joinUs_position_name_text {
        color: @primary-color;
      }
    }
  }
  .mj_joinUs_global_position_body {
    width: 100%;
    opacity: 0;
    transition: @animationDelay;
    position: relative;
    overflow: hidden;
    .mj_joinUs_global_position {
      max-width: 1280px;
      margin: 40px auto 0;
      .mj_joinUs_global_position_title {
        display: flex;
        height: auto;
        justify-content: space-between;
        margin: 40px 0;
        .mj_joinUs_global_position_title_item {
          text-align: center;
          height: 40px;
          line-height: 40px;
          background: #3B3934;
          flex: 1;
          font-size: 20px;

          font-weight: 300;
          color: #FEFEFE;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .mj_joinUs_global_position_list {
        height: calc(100vh - 570px);
        overflow-y: auto;
        .mj_joinUs_global_position_list_item {
          width: 100%;
          display: flex;
          text-decoration: none;
          justify-content: space-between;
          align-self: center;
          font-size: 20px;

          font-weight: 300;
          color: #FEFEFE;
          cursor: pointer;
          transition: 0.15s;
          &:last-child {
            border-bottom: 1px solid #3B3934;
          }
          &:hover {
            background: #3B3934;
          }
          div {
            border-top: 1px solid #3B3934;
            flex: 1;
            height: 58px;
            line-height: 58px;
            text-align: center;
          }
        }
      }
    }
    .mj_joinUs_global_position_closeBtn{
      width: 268px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2D2B27;
      font-size: 20px;
      font-family: Visby CF, sans-serif;
      font-weight: 300;
      color: #FEFEFE;
      position: absolute;
      bottom: 40px;
      right: 0;
      left: 0;
      margin: 0 auto;
      cursor: pointer;
      transition: @animationDelay;
      &:hover {
        background: #3B3934;
      }
    }
  }
  .mj_joinUs_global_position_body_show {
    opacity: 1;
    height: calc(100vh - 336px);
    overflow: auto;
  }
  .mj_joinUs_moka {
    height: 80px;
    line-height: 80px;
    width: 100%;
    background: #2D2B27;
    text-align: center;
    font-size: 16px;

    font-weight: 300;
    color: #FEFEFE;
    cursor: pointer;
    &:hover {
      background: #3B3934;
    }
  }
  .mj_joinUs_bottom_text {
    text-align: center;
    width: 100%;
    font-size: 12px;
    scale: 0.8;
    font-family: Mark Pro, sans-serif;
    font-weight: 500;
    color: #483D3C;
    position: absolute;
    bottom: 12px;
  }
  .mj_joinUs_international_title {
    font-size: 26px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #858585;
  }
  .mj_joinUs_international_dot {
    width: 4px;
    height: 4px;
    background: #858585;
    margin: 0 15px;
  }
}
@media screen and (max-width: 1632px){
  .aboutUs_link_active {
    width: calc(83vw - 710px) !important;
  }
  .aboutUs_link_subtitle {
    right: 50px !important;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;