.mj_footer_container {
  width: 100%;
  overflow: hidden;
  padding: 62px 0 69px;
  transition: @animationDelay;
  .mj_footer_title_bar {
    height: 23px;
    text-align: center;
    font-size: 23px;

    font-weight: 300;
    color: #FFF;
    line-height: 23px;
    margin-bottom: 34px;
  }
  .mj_footer_content {
    .mj_footer_content_left {
      width: 34%;
      padding: 32px 0 34px 8vw;
      position: relative;
    }
    .mj_footer_content_left_contactUs_title {
      font-size: 14px;

      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 15px;
    }
    .mj_footer_content_left_contact_way {
      display: flex;
      margin-bottom: 42px;
      div {
        font-size: 12px;

        font-weight: 300;
        color: #BDBDBD;
        &:first-child{
          margin-right: 16px;
        }
      }
    }
    .mj_footer_content_left_contact_link {
      display: flex;
      justify-content: flex-start;
      .mj_footer_content_left_contact_linBtn {
        margin-right: 40px;
        cursor: pointer;
        font-size: 30px !important;
        width: 30px;
        overflow: hidden;
        height: 30px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 10px;
        overflow: inherit;
      }
    }
    .mj_footer_content_left_contact_linBtn_wechat {
      position: relative;
      &:hover {
        .mj_footer_content_left_wechat_QRCode {
          opacity: 1;
          pointer-events: auto;
          //display: block;
        }
      }
      .mj_footer_content_left_wechat_QRCode {
        position: absolute;
        pointer-events: none;
        left: 50%;
        transform: translateX(-50%);
        top: -140px;
        transition: 0.3s;
        opacity: 0;
      }
      .mj_footer_content_left_wechat_QRCodeImage {
        box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
      }
    }
    .mj_footer_content_left_border {
      width: 1px;
      height: 102px;
      background: #BDBDBD;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .mj_footer_content_right_protocol {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 27px;
      overflow: hidden;
      text-align: center;
      margin-bottom: 17px;
      div {
        font-size: 23px;

        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
        &:hover {
          color: #eeeeee;
        }
        &.line {
          height: 20px;
          width: 2px;
          background: #FFFFFF;
          margin: 0 20px;
        }
      }
    }
    .mj_footer_healthGame {
      font-size: 19px !important;

      font-weight: 300;
      color: #E5E5E5;
      margin-bottom: 17px;
      text-align: center;
      a {
        color: #E5E5E5;
      }
      div {
        margin-bottom: 17px;
      }
    }
    .registerSystemInfo {
      font-size: 19px;
      text-align: center;
    }
  }
}
.mj_footer_container_zh_tw {
  padding: 0;
}
.protocol_link {
  font-size: 22px;

  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none !important;
}
.mj_show_footer {
  transform: translateY(-170px);
}
.mj_footer_global_container {
  .mj_footer_global_protocol {
    display: flex;
    margin: 0 auto 17px;
    width: 100%;
    justify-content: center;
    align-items: center;

    font-weight: 300;
    color: #FEFEFE;
    div:nth-child(2) {
      height: 20px;
      width: 2px;
      background: #Fff;
      margin: 0 20px;
    }
    div:nth-child(2n+1) {
      cursor: pointer;
    }
  }
  .mj_footer_global_bottom_text{
    width: 100%;
    text-align: center;
    font-size: 19px;
    font-family: SimHei, sans-serif;
    font-weight: 300;
    color: #9A9A9A;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;