.mj_homePage_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: @animationDelay;
  .mj_homePage_image {
    height: 100%;
    object-fit: cover;
    transition: @animationDelay;
  }

  .mj_homePage_scrollTips {
    width: 100%;
    height: 50px;
    text-align: center;
    color: #eaeaea;
    cursor: pointer;
    background: linear-gradient(to bottom, rgba(51,51,51,.3), rgba(51,51,51,0));
    z-index: 1;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 55px;
    transform: rotateX(180deg);
  }
  .mj_homePage_scrollTips_svg {
    position: relative;
    will-change: auto;
    scale: 1.3;
    animation: upAndDown 1.2s infinite linear;
  }
  @keyframes upAndDown {
    0% {
      top: 0;
    }
    50% {
      top: 5px;
    }
    100% {
      top: 0;
    }
  }
}
.mj_homePage_container_hide {
  opacity: 0;
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;