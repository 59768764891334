.mj_container {
  height: var(--vh);
  .swiper_container{
    position: relative;
    width: 100%;
    height: var(--vh);
    margin: auto;
  }
  .swiper_homePageContainer {
    background: #FFF;
  }
  .mj_container_bgcImage {
    top: 0;
    left: 0;
    transform: translateY(300px);
    position: absolute;
    object-fit: cover;
  }
  .dimmer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(53,53,53,.7);
    z-index: 0;
  }
  .mj_container_bgcImage_active {
    transform: translateY(0) !important;
    transition: @animationDelay !important;
  }
  .mj_joinUsAndNewsSwiper_SwiperSlide {
    height: auto;
    margin-bottom: 6px;
  }
}

.mj_landscape_tips {
  width: 100vw;
  height: 100%;
  background-color: black;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .mj_landscape_tips_image {
    width: 75PX;
    height: auto;
    margin-bottom: 20PX;
    transform: rotate(-90deg);
  }
  .mj_landscape_tips_text {
    color: #FFF;
    font-size: 16PX;
  }
}
// @media(orientation:portrait) {
//   .mj_landscape_tips {
//     display: none;
//   }
// }

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;