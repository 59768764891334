.mj_honor_container {
  height: var(--vh);
  background: #f0f2f5;
  overflow: auto;
  padding-left: 4px;
  padding-top: 1.06rem;
  .mj_container_bgc {
    position: absolute;
    left: 0;
    top: 0;
  }
  .mj_honor_list {
    transform: translateY(200px);
    transition: @animationDelay;
    position: relative;
    width: 100%;
    height: calc(var(--vh) - 106px);
    margin: auto;
    .mj_honor_Title {
      // padding-top: 106px;
      width: 100%;
      height: 180px;
      padding-left: 41px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .mj_honor_Title_topTitle {
        font-size: 50px;
        color: #353535;
      }
      .mj_honor_Title_bottomTitle {
        font-size: 25px;

        font-weight: bold;
        color: @primary-color;
      }
    }
    .mj_honor_list_item {
      width: 100%;
      display: flex;
      height: auto;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      .mj_honor_list_item_detail_container {
        .mj_honor_list_item_detail {
          position: relative;
          padding: 0 15px 0 20px;
          width: 613px;
          min-height: 94px;
          line-height: 94px;
          background: #2D2B27;
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }

          .mj_honor_list_item_detail_bgcYear{
            position: absolute;
            font-size: 78px;
            font-family: Visby CF, sans-serif;
            font-weight: 100;
            color: #413E33;
            right: 15px;
            opacity: 0.4;
            width: fit-content;
          }
          .mj_honor_list_item_detail_text {
            line-height: 40px;
            width: 100%;
            font-size: 23px;
            word-break: break-word;

            font-weight: 800;
            color: #FFFFFF;
            bottom: 12px;
            text-align: left;
            right: 23px;
            top: 44px;
          }
        }
        .mj_honor_list_item_title {
          width: 100%;
          height: 34px;
          line-height: 40px;
          padding: 0 13px 0 34px;
          display: flex;
          background: #22211E;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
        }
        .mj_honor_list_item_title_text {
          font-size: 23px;

          font-weight: 300;
          color: #81ADFF;
        }
        .mj_honor_list_item_title_subTitle {
          width: 181px;
          font-size: 10px;
          font-family: Visby CF, sans-serif;
          font-weight: 100;
          color: #3F3D32;
          display: flex;
          justify-content: space-between;
          text-align-last: justify;
        }
      }

      .mj_honor_list_item_year {
        display: flex;
        height: 34px;
        line-height: 34px;
        background: #22211E;
        width: 160px;
        justify-content: flex-end;
        .mj_honor_list_item_yearTitle {
          font-size: 14px;
          font-family: Visby CF, sans-serif;
          font-weight: 300;
          color: #FFFFFF;
          padding-top: 6px;
          margin-right: 6px;
        }
        .mj_honor_list_item_yearNum {
          font-size: 32px;
          font-family: Visby CF, sans-serif;
          font-weight: 500;
          color: #FFF;
        }
      }
    }
  }
  .mj_honor_bottom_bar {
    width: 613px;
    height: 40px;
    background: #181818;
    opacity: 0.98;
    font-size: 23px;
    font-family: Visby CF, sans-serif;
    font-weight: 500;
    color: #FFE87B;
    line-height: 40px;
    text-align: center;
    margin-top: 6px;
  }
}

.mj_honor_container_show {
  transform: translateY(0) !important;
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;