.mj_productDetails_container {
  width: 100%;
  height: 509px;
  position: absolute;
  bottom: 62.5px;
  transition: @animationDelay;
  pointer-events: auto;

  &:lang(kr) {
    height: 560px;
    bottom: 12px;
  }

  .mj_productDetails_content {
    position: relative;
    padding: 43px 43px 0;
  }

  .mj_productDetails_title_container {
    position: relative;
    height: 87px;
    border-bottom: 2px solid rgba(196, 196, 196, .5);
    overflow: hidden;
    background: #fff;
    z-index: 1;
  }

  .mj_productDetails_title_container_krOjp {
    height: 110px;
  }

  .productsDetails_title_body {
    display: flex;
    align-items: baseline;
    position: absolute;
    z-index: 1;
    top: 160px;
    height: 48px;
    line-height: 48px;
    .productsDetails_title_name {
      font-size: 48px;

      font-weight: bold;
      color: #3C3232;
      margin-right: 12px;
    }
    .productsDetails_title_subTitle {
      font-size: 28px;
      font-family: Alfphabet, sans-serif;
      font-weight: normal;
      color: #3C3232;
    }
    .productsDetails_title_subTitle_krOjp {
      padding-top: 0;
    }

  }
  .productsDetails_link_body {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    bottom: -84px;
    padding: 0 6px;
    transition: @animationDelay;
    .productsDetails_link_btn{
      height: 84px;
      line-height: 84px;
      cursor: pointer;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      svg {
        fill: #FFF
      }
    }
    .productsDetails_link_website {
      width: 100%;
      background: #3F3F3F;
      font-size: 23px;
      text-shadow: 0 0 5px rgba(0,0,0,0.12);

      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .productsDetails_link_website_dot {
        width: 5px;
        height: 5px;
        background: #FFF;
      }
      .productsDetails_link_website_dot_left {
        margin-right: 12px;
      }
      .productsDetails_link_website_dot_right {
        margin-left: 12px;
      }
    }
    .productsDetails_link_bilibili{
      font-size: 40px;
      //background: #41BFEC;
      background: #BDBDBD;
    }
    .productsDetails_link_weibo{
      font-size: 40px;
      svg {
        //fill: #E74746;
        fill: #BDBDBD;
      }
    }
    .productsDetails_link_wechat{
      font-size: 40px;
      background: #BDBDBD;
      position: relative;
      //background: #07C160;
      overflow: inherit;
      &:hover {
        .productsDetails_link_wechat_QRCode {
          opacity: 1;
          pointer-events: auto;
          //display: block;
        }
      }
      .productsDetails_link_wechat_QRCodeImage {
        box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
      }
      .productsDetails_link_wechat_QRCode {
        pointer-events: none;
        position: absolute;
        top: -140px;
        //display: none;
        transition: 0.3s;
        opacity: 0;
      }
    }
    .productsDetails_link_facebook{
      svg {
        //fill: #3f75ea;
        fill: #BDBDBD;
      }
      scale: 1.7;
      font-size: 40px !important;
      overflow: hidden;
    }
    .productsDetails_link_twitter {
      width: 40px;
      //background: #4f99e9;
      background: #BDBDBD;

      border-radius: 2px;
    }
    .productsDetails_link_youtube {
      font-size: 40px !important;
      //background: #e93323;
      background: #BDBDBD;
      border-radius: 2px;
      overflow: hidden;
    }
    .productsDetails_link_cafe {
      font-size: 40px;
      border-radius: 2px;
      overflow: hidden;
      svg {
        //fill: #03c75a;
        fill: #BDBDBD;
      }
    }
  }
  .productsDetails_link_body_show {
    bottom: 6px;
  }
  .productsDetails_title_body_krOjp {
    flex-direction: column;
  }
  .productsDetails_title_body_show {
    top: 0;
    transition: @animationDelay;
  }

  .mj_productDetails_desc_body {
    position: relative;
    overflow: hidden;
    height: 270px;
  }

  .mj_productDetails_desc {
    white-space: pre-line;
    font-size: 22px;

    font-weight: 400;
    color: #404040;
    line-height: 34px;
    padding-top: 40px;
    position: absolute;
    top: -240px;
    z-index: -1;
    i{
      font-size: 16PX;
    }
  }
  .mj_productDetails_desc_show {
    top: 0;
    transition: @animationDelay;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;