
.mj_products_container {
  position: relative;
  overflow: hidden;
  padding-right: 0;
  height: var(--vh);
  transition: 0.6s;
  background: transparent;
  :global {
    & .no_touch{
      touch-action: none;
    }
  }

  .swiper_container{
    position: relative;
    width: 100%;
    margin: auto;
    transition: 0.6s;
  }
  .swiperWrapper{
    min-height: 750px;
  }
  .mj_aboutUs_loop_picSelf {
    object-fit: cover;
    object-position: 50% 0;
    transition: 0.6s;
    // height: 100%;
    // background-size: contain;
    // background-repeat: no-repeat;
  }
  .mj_products_loop_pic {
    width: 100%;
    height: calc(var(--vh) - 138px);
    background: #232323;
    // background: #FFF;
    transition: 0.6s;
  }
  .mj_products_loop_pic_showDetail {
    transform: translateX(-260px);
  }
  .mj_products_loop_picSelf {
    object-fit: cover;
    object-position: 50% 50%;
  }
  .mj_ProductDetails_body {
    position: relative;
    z-index: 2;
    opacity: 0;
    height: auto;
  }
  .mj_products_bottom_bar {
    position: absolute;
    bottom: 6px;
    height: 63px;
    background: #e1e1e1;
    width: calc(100% - 12px);
    left: 6px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 55px;
    .mj_homePage_scrollTips_svg {
      position: relative;
      scale: 1.3;
      animation: upAndDown 1.2s infinite linear;
    }
    @keyframes upAndDown {
      0% {
        top: 0;
      }
      50% {
        top: 5px;
      }
      100% {
        top: 0;
      }
    }
  }
  .mj_products_swiper_process {
    width: 475px;
    margin-left: 6px;
    height: 6px;
    position: absolute;
    z-index: 0;
    left: 6px;
    bottom:62.5px;
    // transform: translate(-100%, -510px);
    transform: translate(-6px, 0px);
    background: #C7C7C7;
    transition: 0.6s;
    opacity: 0;
    .mj_products_swiper_process_bar {
      height: 6px;
      width: 100%;
      background: @primary-color;
      // animation-name: process;
      // animation-iteration-count: infinite;
      // animation-timing-function: linear;
      // animation-duration: 6400ms;
      // box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
    }
    .test_proce{
      height: 4px;
      width: 100%;
      background: #FFE87B;
   
      box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
     animation: process 6000ms linear none ;
    //  animation-delay: 400;
  }
  @keyframes process {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
    :global {
     
      .test_proce:local{
        height: 4px;
        width: 100%;
        background: #FFE87B;
     
        box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
       animation: process 10*6000ms linear none ;
      //  animation-delay: 400;
    }
.test_proce2:local{
  animation: process 6000ms linear infinite ;
}
 
 
  }
    @keyframes process {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
  }
  .mj_products_swiper_process_show {
    transform: translate(0, -510px);
  }
  .mj_products_video {
    -webkit-tap-highlight-color: transparent;
    width: calc(100vw - 12px);
    position: relative;
    // display: grid;
    // grid-template-columns:50% 1fr;
    // grid-gap: 6px;

    cursor: pointer;
    z-index: 10;
    height: 125px;
    user-select: none;
    will-change: auto;
    right: 0;
    &.mj_products_video_dir{
      animation: move 0.3 linear forwards;
    }
    .mj_products_video_playBtn {
      height: 125px;
      width: 125px;
      background: rgba(25,23,23,0.95);
      display: flex;
      justify-content: center;
      align-items: center;
      .mj_products_video_playBtn_img {
        width: 50px;
        height: auto;
      }
      .mj_products_triangle {
        width: 50px;
        height: 50px;
        border: 2px solid #555555;
        border-radius: 50%;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #555555;
        }
        //width: 0;
        //height: 0;
        //transform: translateX(32%);
        //border: 20px solid transparent;
        //border-left: 28px solid yellow;
      }
    }
    .mj_products_video_playDesc {
      display: grid;
      grid-template-columns: 50% 1fr;

      grid-column-gap:4px; 
      height: 100%;
  
      font-size: 19px;
      font-family: Mark Pro, sans-serif;
      font-weight: 400;
      color: #F9F9F9;
      position: relative;
   
      .mj_products_flex_card {
        display: flex;
       flex:1 ;
       position: relative;
       justify-content: center;
       align-items: center;
       height: 100%;
       color: #fff;
       &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(35,35,35,.65);
        z-index: 1;
      }
      }
      .mj_products_flex_card_1{
        background:url('../../public/images/products/p1/slogn.png') #E5E5E5 ;
        //background-position: -120% 50%;
        background-position: -40PX 0%;
        background-size: 100% 100%;
        // background-size: cover;
        background-repeat: no-repeat;
      }
      .mj_products_flex_card_2{
        background:url('../../public/images/products/p3/slogn.jpg') ;
        // background-position: -50% 50%;
        // background-size: cover;
        background-position: right -20PX center;

        background-size: 115% 115%;
      }
      .mj_products_mask{
        &::before {
          opacity: 0.2;
        }

      }
      div{
        z-index: 1;
      }
      .mj_products_video_playDesc_img {
        object-fit: cover;
        position: absolute;
        left: 600px;
      }
    
    }
 
    // @keyframes move{
    //   0%{
    
    //   }
    //   100%{
      
    // transform: scale(0.8);
    
    //   }
    
    // }
  }
 
  .mj_products_detail_toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 54px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    transition: cubic-bezier(0, 0, 0, 0.8) 0.56s !important;
  }
}

.mj_products_container_noActive {
  transform: translateY(20%);
}
.mj_products_swiper_noActive {
  height: calc(var(--vh) - 522px) !important;
}
.title{
  font-size: 22px;
  z-index: 1;
font-family:"Microsoft YaHei", "Microsoft YaHei UI","微软雅黑", sans-serif;
font-weight: 400;
color: #FFFFFF;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.mj_products_mask{
  .title{
   opacity: 1;
  }
}


.swiperW100{
  width: 100%;


}
.mj_products_animation{
  display: flex;
  flex-direction: column;
  position: relative;
}
.showScal{
  .swiperW100{
    margin-bottom: 74px;

  }
  .mj_products_swiper_process{
    opacity: 1;
  }
  .mj_products_flex_card_2{
    background-position: -55% 50% !important;
    background-size: cover !important;
  }
  .mj_products_flex_card_1{
    background-position: 40% 50% !important;
    background-size: cover !important;
  }

}
.bar_bottom{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 6px;
  width: calc(100vw - 12px);
  height: 125px;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
}


@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;