.mj_ContactUs_container {
  background: #232323;
  position: relative;
  .mj_ContactUs_title {
    height: 155px;
    padding-left: 59px;
    display: flex;
    line-height: 155px;
    border-bottom: 3px solid #2E2E2E;
    .mj_ContactUs_title_compName {
      font-size: 45px;
      font-weight: bold;
      color: #FFFFFF;
      margin-right: 9px;
    }
    .mj_ContactUs_title_subTitle {
      font-size: 24px;
      font-weight: normal;
      color: @primary-color;
      font-family: Alfphabet, sans-serif;
      padding-top: 12px;
    }
  }

  .mj_ContactUs_content {
    padding: 55px 0 63px 59px;
    .mj_ContactUs_content_title {
      display: flex;
      margin-bottom: 44px;
    }
    .mj_ContactUs_content_titleName {
      font-size: 25px;

      font-weight: bold;
      color: #FFFFFF;
      margin-right: 11px;
    }
    .mj_ContactUs_content_subTitleName {
      font-size: 17px;
      font-family: Mark Pro, sans-serif;
      font-weight: 400;
      color: #4B4B4B;
      padding-top: 10px;
    }
  }

  .mj_ContactUs_contactWay {
    margin-bottom: 91px;
    .mj_ContactUs_contactWay_content {
      font-size: 22px;

      font-weight: 300;
      color: #FFFFFF;
      line-height: 26px;
    }
  }
  .mj_ContactUs_contactWay_social {
    display: flex;
    justify-content: flex-start;
  }
  .mj_ContactUs_contactWay_socialItem{
    font-size: 45px;
    margin-right: 58px;
    color: #eaeaea;
    cursor: pointer;
  }
  .mj_ContactUs_contactWay_socialItem_weChat {
    position: relative;
    &:hover {
      .mj_ContactUs_contactWay_socialItem_weChat_QRCode {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .mj_ContactUs_contactWay_socialItem_weChat_QRCode {
    position: absolute;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
    top: -220px;
    transition: 0.3s;
    opacity: 0;
  }
  .mj_ContactUs_contactWay_wechat_QRCodeImage {
    box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
  }
  .mj_ContactUs_bottom_btn {
    position: absolute;
    bottom: -100px;
    width: 100%;
    left: 0;
    height: 94px;
    line-height: 46px;
    background: @primary-color;
    font-size: 28px;
    font-family: Visby CF, sans-serif;
    font-weight: 600;
    color: #232323;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mj_drawer_closeBtn {
    background: transparent;
    width: 24px;
    height: 24px;
    margin-right: 36px;
  }
}

@primary-color: #FFE25A;@animationDelay: 0.6s;@maxWidth: 1528px;